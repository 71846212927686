.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(8, 64, 133, 0.5); /* Semi-transparent background */
    z-index: 999; /* Ensure it's above other elements */
}

.loader-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loading-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7); /* Dark overlay for text */
    color: white;
    padding: 8px 15px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
}
